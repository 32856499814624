const commonFeature = require("./components/commonFeature");

/**
 * Get display information related to screen size
 * @param {jQuery} element - the current carousel that is being used
 * @returns {Object} an object with display information
 */
function screenSize(element) {
    var result = {
        itemsToDisplay: null,
        sufficientSlides: true
    };
    var extraSmallDisplay = element.data("xs");
    var smallDisplay = element.data("sm");
    var mediumDisplay = element.data("md");
    var numberOfSlides = element.data("number-of-slides");

    if (commonFeature.isBreakpoint("<sm")) {
        result.itemsToDisplay = extraSmallDisplay;
    } else if (commonFeature.isBreakpoint(">=sm") && commonFeature.isBreakpoint("<md")) {
        result.itemsToDisplay = smallDisplay;
    } else if (commonFeature.isBreakpoint(">=md")) {
        result.itemsToDisplay = mediumDisplay;
    }

    if (result.itemsToDisplay && numberOfSlides <= result.itemsToDisplay) {
        result.sufficientSlides = false;
    }

    return result;
}

/**
 * Makes the next element to be displayed next unreachable for screen readers and keyboard nav
 * @param {jQuery} element - the current carousel that is being used
 */
function hiddenSlides(element) {
    var carousel;

    if (element) {
        carousel = element;
    } else {
        carousel = $(".product-slider-component .carousel, .bassett-carousel-pd .carousel, .hero-carousel-pd .carousel, .experience-commerce_layouts-carousel .carousel, .experience-einstein-einsteinCarousel .carousel, .experience-einstein-einsteinCarouselCategory .carousel, .experience-einstein-einsteinCarouselProduct .carousel");
    }

    var screenSizeInfo = screenSize(carousel);

    var lastDisplayedElement;
    var elementToBeDisplayed;

    switch (screenSizeInfo.itemsToDisplay) {
        case 2:
            lastDisplayedElement = carousel.find(".active.carousel-item + .carousel-item");
            elementToBeDisplayed = carousel.find(".active.carousel-item + .carousel-item + .carousel-item");
            break;
        case 3:
            lastDisplayedElement = carousel.find(".active.carousel-item + .carousel-item + .carousel-item");
            elementToBeDisplayed = carousel.find(".active.carousel-item + .carousel-item + .carousel-item + .carousel-item");
            break;
        case 4:
            lastDisplayedElement = carousel.find(".active.carousel-item + .carousel-item + .carousel-item + .carousel-item");
            elementToBeDisplayed = carousel.find(".active.carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item");
            break;
        case 6:
            lastDisplayedElement = carousel.find(".active.carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item");
            elementToBeDisplayed = carousel.find(".active.carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item");
            break;
        default:
            break;
    }

    carousel.find(".active.carousel-item").removeAttr("tabindex").removeAttr("aria-hidden");
    carousel.find(".active.carousel-item").find("a, button, details, input, textarea, select")
        .removeAttr("tabindex")
        .removeAttr("aria-hidden");

    if (lastDisplayedElement) {
        lastDisplayedElement.removeAttr("tabindex").removeAttr("aria-hidden");
        lastDisplayedElement.find("a, button, details, input, textarea, select")
            .removeAttr("tabindex")
            .removeAttr("aria-hidden");
    }

    if (elementToBeDisplayed) {
        elementToBeDisplayed.attr("tabindex", -1).attr("aria-hidden", true);
        elementToBeDisplayed.find("a, button, details, input, textarea, select")
            .attr("tabindex", -1)
            .attr("aria-hidden", true);
    }
}

/**
 * Makes the current element number to be displayed
 * @param {jQuery} element - the current carousel that is being used
 */
function handleCounter(element) {
    var carousel;

    if (element) {
        carousel = element;
    } else {
        carousel = $(".hero-carousel-pd .carousel");
    }

    var $currentSlideNum = carousel.find(".pd-carousel__current-slide");
    var currentSlide = +$currentSlideNum.text();
    var numberOfSlides = +carousel.data("number-of-slides");

    if (numberOfSlides > currentSlide) {
        $currentSlideNum.text(currentSlide + 1);
    } else if (numberOfSlides == currentSlide) {
        $currentSlideNum.text(1);
    }
}

/**
 * Determines carousel item height by the tallest item in each carousel
 * @param {jQuery} $carousels - All selected carousels on the page
 */
function adjustHeight($carousels) {
    for (var i = 0; i < $carousels.length; i++) {
        var $carousel = $($carousels[i]);
        var tileHeights = $carousel.find(".carousel-item").map(function () {
            return $(this).height();
        }).get();
    
        var maxHeight = Math.max.apply(null, tileHeights);
    
        $carousel.find(".carousel-item").height(maxHeight);
    }
}

$(document).ready(function () {
    hiddenSlides();

    adjustHeight($(document).find(".bassett-carousel-pd"));

    $(window).on("breakpoint:change", function () {
        hiddenSlides();
    });

    $("body").on("carousel:setup", function () {
        hiddenSlides();
    });

    $(".product-slider-component .carousel, .bassett-carousel-pd .carousel, .hero-carousel-pd .carousel, .experience-commerce_layouts-carousel .carousel, .experience-einstein-einsteinCarousel .carousel, .experience-einstein-einsteinCarouselCategory .carousel, .experience-einstein-einsteinCarouselProduct .carousel").on("touchstart", function (touchStartEvent) {
        var screenSizeInfo = screenSize($(this));

        if (screenSizeInfo.sufficientSlides) {
            var xClick = touchStartEvent.originalEvent.touches[0].pageX;
            $(this).one("touchmove", function (touchMoveEvent) {
                var xMove = touchMoveEvent.originalEvent.touches[0].pageX;
                if (Math.floor(xClick - xMove) > 5) {
                    $(this).carousel("next");
                } else if (Math.floor(xClick - xMove) < -5) {
                    $(this).carousel("prev");
                }
            });
            $(".product-slider-component .carousel, .bassett-carousel-pd .carousel, .hero-carousel-pd .carousel, .experience-commerce_layouts-carousel .carousel, .experience-einstein-einsteinCarousel .carousel, .experience-einstein-einsteinCarouselCategory .carousel, .experience-einstein-einsteinCarouselProduct .carousel").on("touchend", function () {
                $(this).off("touchmove");
            });
        }
    });

    $(".product-slider-component .carousel, .bassett-carousel-pd .carousel, .hero-carousel-pd .carousel, .experience-commerce_layouts-carousel .carousel, .experience-einstein-einsteinCarousel .carousel, .experience-einstein-einsteinCarouselCategory .carousel, .experience-einstein-einsteinCarouselProduct .carousel").on("slide.bs.carousel", function (e) {
        var activeCarouselPosition = $(e.relatedTarget).data("position");
        $(this).find(".pd-carousel-indicators .active").removeClass("active");
        $(this).find(".pd-carousel-indicators [data-position='" + activeCarouselPosition + "']").addClass("active");
        $(this).find(".carousel-indicators .active").removeClass("active");
        $(this).find(".carousel-indicators [data-position='" + activeCarouselPosition + "']").addClass("active");

        handleCounter($(this));

        $(this).find(".carousel-control-prev, .carousel-control-next").addClass("disabled");

        var extraSmallDisplay = $(this).data("xs");
        var smallDisplay = $(this).data("sm");
        var mediumDisplay = $(this).data("md");

        var arrayOfSlidesToDisplay = [];

        if (!$(this).hasClass("insufficient-xs-slides")) {
            arrayOfSlidesToDisplay.push(extraSmallDisplay);
        }

        if (!$(this).hasClass("insufficient-sm-slides")) {
            arrayOfSlidesToDisplay.push(smallDisplay);
        }

        if (!$(this).hasClass("insufficient-md-slides")) {
            arrayOfSlidesToDisplay.push(mediumDisplay);
        }

        var itemsToDisplay = Math.max.apply(Math, arrayOfSlidesToDisplay);

        var elementIndex = $(e.relatedTarget).index();
        var numberOfSlides = $(".carousel-item", this).length;
        var carouselInner = $(this).find(".carousel-inner");
        var carouselItem;

        if (elementIndex >= numberOfSlides - (itemsToDisplay - 1)) {
            var it = itemsToDisplay - (numberOfSlides - elementIndex);
            for (var i = 0; i <= it; i++) {
                // append slides to end
                if (e.direction === "left") {
                    if (i !== it) {
                        carouselItem = $(".carousel-item", this).eq(i);

                        $(carouselItem).appendTo($(carouselInner));
                    }
                } else {
                    carouselItem = $(".carousel-item", this).eq(0);

                    $(carouselItem).appendTo($(carouselInner));
                }
            }
        }
    });

    $(".product-slider-component .carousel, .bassett-carousel-pd .carousel, .hero-carousel-pd .carousel, .experience-commerce_layouts-carousel .carousel, .experience-einstein-einsteinCarousel .carousel, .experience-einstein-einsteinCarouselCategory .carousel, .experience-einstein-einsteinCarouselProduct .carousel").on("slid.bs.carousel", function () {
        hiddenSlides($(this));
        var $carousel = $(this);
        setTimeout(function () {
            $carousel.find(".carousel-control-prev, .carousel-control-next").removeClass("disabled");
        }, 500);
    });


    // Auto play 
    $("body").on("click", ".carousel-play", function () {
        const $carousel = $(this).parents(".auto-slide");

        $carousel.carousel("cycle");
        $carousel.find(".carousel-slide-controls").removeClass("carousel-slide-play");
    });

    $("body").on("click", ".carousel-pause", function () {
        const $carousel = $(this).parents(".auto-slide");

        $carousel.carousel("pause");
        $carousel.find(".carousel-slide-controls").addClass("carousel-slide-play");
    });

    // Init slider for Recommendation Einstein
    $("body").on("initRecommendationSlider", function () {
        var $recommendationCarousel = $(".product-slider-component.recommendations .einstein-carousel");
        $recommendationCarousel.find("script").remove();
        $recommendationCarousel.find(".slick-slider").slick($recommendationCarousel.data("slick"));
    });
});
